<template>
    <v-container>
        <Widget title="Transaction Statement">
            <v-row>
              <v-col cols="8">
                <CustomerFacingTransactions v-if="instrument" :instrument="instrument" />
              </v-col>
              <v-col cols="4">
                <Heading large> Upload loan contract if contract missing </Heading>
                <v-select
                    label="loan document and letters"
                    :items="documentTypes"
                    item-value="value"
                    item-text="name"
                    v-model="documentType"
                    :rules="required ? [requiredRule] : []"
                ></v-select>
                <DocumentInput v-model="instrument.contract_doc" dense :type="documentType || 'contract'" :typeId="uuid" :modelIdentifier="instrumentId" />
              </v-col>
            </v-row>
        </Widget>
    </v-container>
</template>

<script>

    import lookups from '@/mixins/lookups';
    import Widget from "@/components/Widget";
    import DocumentInput from "@/components/DocumentInput";
    import Heading from '@/components/Heading';
    import CustomerFacingTransactions from "@/views/instruments/CustomerFacingTransactions"
    export default{
        components: { Widget, CustomerFacingTransactions, DocumentInput, Heading},

        data() {
            return {
                instrument: undefined,
                documentType: undefined,
                documentTypes: [
                  {name: "Loan Agreement contract", value: "contract"}, 
                  {name: "Demand Letter", value: "demand_letter"}
                ]
            }
        },

        computed: {
            instrumentId() {return this.$route.query.id},
            uuid() {return this.$route.query.uuid}
        },

        mounted() {
            this.remoteLookup('instrument', {
                uuid: this.uuid,
                id: this.instrumentId,

            }).then( instrument => {
                console.log("response: ", instrument)
                this.instrument = instrument 
            }).catch(e => this.instrument = null);
        },

        mixins: [lookups],
    }

</script>