<template>
  <PageHeader :title="'New client'" >

    <Stepper :tabs="tabs" :captions="captions" :complete="complete" :disabled="disabled" :error="error" :invalid="invalid" @position="stepChange($event)" ref="stepper">

      <template v-slot:Basics>
        <Basics v-model="customer" @valid="validity['Basics']=$event" ref="basics" />
      </template>

      <template v-slot:Fingerprints>
        <Fingerprints v-model="customer" @error="errorList.push('Fingerprints')" @valid="validity['Fingerprints']=$event" ref="fingerprints" />
      </template>

      <template v-slot:Employment>
        <Employment v-model="customer" @valid="validity['Employment']=$event" ref="employment" />
      </template>

      <template v-slot:NextOfKin>
        <NextOfKin v-model="customer" @valid="validity['NextOfKin']=$event" ref="nextOfKin" />
      </template>

      <template v-slot:Documents>
        <Documents v-model="customer" @valid="validity['Documents']=$event" ref="documents" />
      </template>

      <template v-slot:Otps>
        <Otps v-model="customer" @valid="validity['Otps']=$event" ref="otps" />
      </template>

      <template v-slot:Other>
        <Other v-model="customer" @valid="validity['Other']=$event" ref="other" />
      </template>

      <template v-slot:Duplicates>
        <Duplicates v-model="customer" @valid="validity['Duplicates']=$event" ref="duplicates" />
      </template>

    </Stepper>


    <div class="d-flex justify-space-between mt-2" style="width: 100%;">
      <div>
        <v-btn color="primary" small dense :disabled="firstStep" @click="$refs.stepper.previousStep();save();" class="mr-1">Save & Previous</v-btn>
        <v-btn color="primary" small dense  @click="save();$refs.stepper.nextStep();">Save & Next</v-btn>
      </div>
      <div>
        <v-btn color="warning" small dense @click="validate" class="mr-1" >
          {{$t('validate')}}
        </v-btn>
        <v-btn :color="(saveLabel=='Save' ? 'primary' : 'error')" small dense @click="save" class="mr-1" :disabled="!saveable" >
          {{saveLabel}}
        </v-btn>
        <v-btn color="success" small dense @click="saveAndDone" :disabled="!valid" >
          Done
        </v-btn>
      </div>
    </div>

  </PageHeader>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import PageHeader from "@/components/PageHeader";
import Stepper from "@/components/Stepper";
import Basics from "@/views/contacts/partners/ultimate_tz/Basics";
import Duplicates from "@/views/contacts/partners/ultimate_tz/Duplicates";
import Fingerprints from "@/views/contacts/partners/ultimate_tz/Fingerprints";
import Employment from "@/views/contacts/partners/ultimate_tz/Employment";
import NextOfKin from "@/views/contacts/partners/ultimate_tz/NextOfKin";
import Documents from "@/views/contacts/partners/ultimate_tz/Documents";
import Otps from "@/views/contacts/partners/ultimate_tz/Otps";
import Other from "@/views/contacts/partners/ultimate_tz/Other";
import lookups from "@/mixins/lookups";

export default {

  components: {
    PageHeader,
    Stepper,
    Basics,
    Duplicates,
    Fingerprints,
    Employment,
    NextOfKin,
    Documents,
    Otps,
    Other,
  },

  data () {
    return {
      customer: { uuid: uuidv4(), demographic: { identity: {} }, economic: { payload: {} }, nextOfKin: {}, regions: [], send_takeon_otp: true },
      customerUpdated: true,

      tabs: ['Basics', 'Fingerprints', 'Employment', 'NextOfKin', 'Other', 'Documents', 'Otps', 'Duplicates'],
      captions: { 'NextOfKin': 'Next Of Kin', 'Other': 'Bank', 'Otps': 'OTPS' },
      validity: { 'Basics': false, 'Duplicates': true, 'Fingerprints': false, 'Employment': false, 'NextOfKin': false, Documents: false, 'Otps': false, 'Other': false }, // have to do this because of reactivity

      firstStep: true,
      lastStep: false,
      saveLabel: 'Save',
      errorList: [],
      fingerprintStatus: undefined
    }
  },

  computed: {
    disabled() { 
      return (this.$store.getters.customer(this.customer.uuid) ? [] : ['Fingerprints', 'Employment', 'NextOfKin', 'Other', 'Documents', 'Otps'])
    },
    complete() { return Object.entries(this.validity).filter(e => e[1]).map(e => e[0]); },
    invalid() { return this.tabs.filter(e => (this.validity[e]===false) ); },
    error() {
      this.validity['Duplicates'] ? [] : this.errorList.push('Duplicates');
      !((this.$store.getters.customer(this.$route.query.uuid) || {}).fingerprintStatus === "another_account_exists")  ? [] : this.errorList.push('Fingerprints');
      this.fingerprintStatus === "another_account_exists" ? this.errorList.push('Fingerprints') : [];
      return this.errorList
    },
    valid() { return this.invalid.length==0; },
    saveable() { return (this.customer.msisdn || '').length==12 },
  },

  watch: {
    customer: {
      deep: true,
      handler(to) {
        this.customerUpdated = true;
      }
    },
  },

  methods: {

    stepChange(to) {
      this.firstStep = (to=='first');
      this.lastStep = (to=='last');
      this.$refs.basics.invalidateMap();
    },

    validate() {
      this.$refs.basics.validate();
      this.$refs.duplicates.validate();
      this.$refs.employment.validate();
      this.$refs.nextOfKin.validate();
      this.$refs.documents.validate();
      this.$refs.other.validate();
      this.$refs.otps.validate();
      this.$refs.fingerprints.validate();
    },

    saveAndDone() {
      this.saveLabel = 'Saved';
      this.customer.customer_at = new Date().toISOString();
      this.customerUpdated = true;
      this.saveCustomer();
      this.$router.push({ name: 'UltimateTzLoanRequest', query: { uuid: this.customer.uuid } });
    },

    save() {
      this.saveLabel = 'Saved';
      let _this = this;
      setTimeout(() => { _this.saveLabel = 'Save'; }, 2000);
      this.saveCustomer();
      // this.$refs.duplicates.checkServerForDuplicates();
    },

    saveCustomer() {
      if (this.customerUpdated) {
        this.customerUpdated = false;
        this.customer.name = [this.customer.firstName,this.customer.middleName,this.customer.surname].map( e => (e || '').trim() ).filter( e => !!e ).join(' ');
        this.$store.dispatch('upsertCustomer', this.customer);
      }
    },

  },

  mounted() {
    const uuid = this.$route.query.uuid;
    if (uuid) {
      this.customer = this.$store.getters.customer(uuid);
      this.customer.demographic = this.customer.demographic || { identity: {} }
      this.customer.nextOfKin = this.customer.nextOfKin || {}
      this.customer.regions = this.customer.regions || []
    }
    this.$refs.basics.invalidateMap();
    console.log("customer ", this.customer)
    const timer = setInterval(() => {
      if(this.customer.fingerprintStatus === undefined) {
        this.remoteLookup('getFingerprintStatus', {uuid: (this.$route.query.uuid || this.customer.uuid)}).then(results => {
          if((results || {}).fingerprintStatus != (undefined || 'initialised')) {
            let vue_object = this.$refs['fingerprints']
            console.log("this is vue object: ", vue_object)
            this.customer.fingerprintStatus = (results|| {}).fingerprintStatus
            this.fingerprintStatus = (results|| {}).fingerprintStatus
            if (this.fingerprintStatus != undefined) {
              vue_object.status = this.fingerprintStatus
              console.log("status: ", vue_object.status)
            } 
          }
        })
      }
    }, 30000);

    if(this.fingerprintStatus != undefined || this.customer.fingerprintStatus != undefined) {
      clearInterval(timer);
    }

    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer);
    });
  },

  created: function() {
    // const timer = setInterval(() => {
    //   if(this.customer.fingerprintStatus === undefined) {
    //     this.remoteLookup('getFingerprintStatus', {uuid: (this.$route.query.uuid || this.customer.uuid)}).then(results => {
    //       if((results || {}).fingerprintStatus != (undefined || 'initialised')) {
    //         let vue_object = this.$refs['fingerprints']
    //         console.log("this is vue object: ", vue_object)
    //         this.customer.fingerprintStatus = (results|| {}).fingerprintStatus
    //         this.fingerprintStatus = (results|| {}).fingerprintStatus
    //         if (this.fingerprintStatus != undefined) {
    //           vue_object.status = this.fingerprintStatus
    //           console.log("status: ", vue_object.status)
    //         } 
    //       }
    //     })
    //   }
    // }, 30000);

    // if(this.fingerprintStatus != undefined || this.customer.fingerprintStatus != undefined) {
    //   clearInterval(timer);
    // }

    // this.$once("hook:beforeDestroy", () => {
    //   clearInterval(timer);
    // });
  },

  mixins: [lookups],

}
</script>

<style scoped>

</style>
