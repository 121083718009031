<template>
  <ServerSideResult :test="instrument">
    <v-container fluid>
      <v-row>
        <v-col cols="1">
          <v-tabs v-model="tab" vertical>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab :disabled="!tabsDetails">
              <v-icon>mdi-account-details</v-icon>
            </v-tab>
            <v-tab :disabled="!tabsSettlement">
              <v-icon>mdi-cash-register</v-icon>
            </v-tab>
            <v-tab :disabled="!tabsRollover">
              <v-icon>mdi-recycle</v-icon>
            </v-tab>
            <v-tab :disabled="!tabsTopup">
              <v-icon>mdi-cash-plus</v-icon>
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="11">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <InstrumentLayout :customer="customer" :instrument="instrument" v-model='highlight' />
              <Balances :customer="customer" :instrument="instrument" :type="type" />
            </v-tab-item>
            <v-tab-item>
              <InstrumentLayout :customer="customer" :instrument="instrument" v-model='highlight' />
              <Pay :customer="customer" :instrument="instrument" :type="type" />
              <Arrears :customer="customer" :instrument="instrument" :type="type"/>
              </v-tab-item>
            </v-tab-item>
            <v-tab-item>
              <InstrumentLayout :customer="customer" :instrument="instrument" v-model='highlight' />
              <Rollover :customer="customer" :instrument="instrument" :type="type" />
              </v-tab-item>
            </v-tab-item>
            <v-tab-item>
              <InstrumentLayout :customer="customer" :instrument="instrument" v-model='highlight' />
                <Topup :customer="customer" :instrument="instrument" :type="type" />
              </v-tab-item>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </ServerSideResult>
</template>

<script>
import lookups from '@/mixins/lookups';

import ServerSideResult from '@/components/ServerSideResult';
import InstrumentLayout from "@/views/customers/partners/ultimate_tz/components/InstrumentLayout"
import Balances from "@/views/customers/partners/ultimate_tz/components/Balances"
import Arrears from "@/views/customers/partners/ultimate_tz/components/checkMeBack/Arrears"
import Pay from "@/views/customers/partners/ultimate_tz/components/Pay"
import Rollover from "@/views/customers/partners/ultimate_tz/components/Rollover"
import Topup from "@/views/customers/partners/ultimate_tz/components/Topup"

export default {

  components: { InstrumentLayout, ServerSideResult, Balances, Pay, Rollover, Topup, Arrears},

  // value is (maybe) and instrument
  props: {
    value: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
    instrumentId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      tab: null,
      instrument: undefined,
      highlight: 'Balance',
    }
  },

  computed: {
    type() { return (this.instrument ? this.instrument.type : undefined); },
    balances() { return (this.instrument || {}); },
    tabsDetails() { return true; },
    tabsSettlement() { return true; },
    tabsRollover() { return ( ( parseInt(this.balances.rollover_amount || 0)>=0 ) && (typeof(this.balances.rollover_amount)=='number' || typeof(this.balances.rollover_amount)=='string') ); },
    tabsTopup() { return ( (parseInt(this.balances.available_balance || 0)>=0 ) && (typeof(this.balances.available_balance)=='number' || typeof(this.balances.available_balance)=='string') ); },
    tabFromHighlight() {
      let r = undefined;
      if (this.highlight=='Balance') { r = 'details'; }
      if (this.highlight=='Payable') { r = 'settlement'; }
      if (this.highlight=='Settlement') { r = 'settlement'; }
      if (this.highlight=='Settlement Amount') { r = 'settlement'; }
      if (this.highlight=='Available Balance') { r = 'topup'; }
      if (this.highlight=='Min Payment to Rollover') { r = 'rollover'; }
      if (this.highlight=='Pay to Roll') { r = 'rollover'; }
      return r;
    }
  },


  watch: {
    instrument(to) { this.$emit('input', to); },
    tab(to) {
      if (to==0) {
        this.highlight = 'Balance';
      } else if (to==1) {
        if (!( this.highlight=='Payable' || this.highlight=='Settlement' || this.highlight=='Settlement Amount' )) {
          if (this.type=='InstrumentUltimateTzCheckMeBack') { this.highlight = 'Payable'; }
          if (this.type=='InstrumentUltimateTzSalaryAdvance') { this.highlight = 'Settlement Amount'; }
          if (this.type=='InstrumentUltimateTzGovernment') { this.highlight = 'Payable'; }
        }
      } else if (to==2) {
        if (!( this.highlight=='Min Payment to Rollover' || this.highlight=='Pay to Roll' )) {
          if (this.type=='InstrumentUltimateTzCheckMeBack') { this.highlight = 'Pay to Roll'; }
          if (this.type=='InstrumentUltimateTzSalaryAdvance') { this.highlight = 'Min Payment to Rollover'; }
        }
      } else if (to==3) {
        this.highlight = 'Available Balance';
      }
    },
    tabFromHighlight(to) {
      let enabled = (to=='details' && this.tabsDetails);
      enabled = enabled || (to=='settlement' && this.tabsSettlement);
      enabled = enabled || (to=='topup' && this.tabsTopup);
      enabled = enabled || (to=='rollover' && this.tabsRollover);
      if (enabled) {
        let id = {
          'details': 0,
          'settlement': 1,
          'rollover': 2,
          'topup': 3,
        }[to];
        this.tab = id;
      }
    },
  },

  mounted() {
    if (this.value) {
      this.instrument = this.value;
    } else {
      this.remoteLookup('instrument', {
        uuid: this.customer.uuid,
        id: this.instrumentId,
      }).then( instrument => this.instrument = instrument ).catch(e => this.instrument = null);
    }
  },

  mixins: [lookups],

}
</script>
