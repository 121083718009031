<template>
  <CheckMeBack :customer="customer" :instrument="instrument" v-if="type=='InstrumentUltimateTzCheckMeBack'" />
  <SalaryAdvance :customer="customer" :instrument="instrument" v-else />
</template>

<script>
import CheckMeBack from "@/views/customers/partners/ultimate_tz/components/checkMeBack/Topup"
import SalaryAdvance from "@/views/customers/partners/ultimate_tz/components/salaryAdvance/Topup"

export default {

  components: { CheckMeBack, SalaryAdvance },

  props: {
    instrument: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

}
</script>
