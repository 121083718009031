<template>
  <transition>
    <keep-alive>
      <v-card class="pa-2 ma-1 w-100">
        <router-view/>
      </v-card>
    </keep-alive>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      inactivityTimer: undefined,
      activityChecker: undefined,
      timer: null,
    }
  },
  methods: {
    resetInactivityTimer() {
        
        this.inactivityTimer = new Date()
      },

      initializeInactivityMonitor() {
        document.addEventListener('mousemove', this.resetInactivityTimer);
        document.addEventListener('keypress', this.resetInactivityTimer);
        document.addEventListener('click', this.resetInactivityTimer);
        document.addEventListener('scroll', this.resetInactivityTimer)
        
        // Initialize the timer
        this.resetInactivityTimer()
      },

      stopMonitoring() {
        document.removeEventListener('mousemove', this.resetInactivityTimer);
        document.removeEventListener('keypress', this.resetInactivityTimer);
        document.removeEventListener('click', this.resetInactivityTimer);
        document.removeEventListener('scroll', this.resetInactivityTimer)
      },

      signOut() { 
        if(this.inactivityTimer < (Date.now() - (20 * 60 * 1000))) {
          clearInterval(this.timer)
          this.stopMonitoring()
          this.$store.dispatch('logout')
        }
      },

      
  },

  mounted() {
    this.timer = setInterval(this.signOut, 10000)
    this.initializeInactivityMonitor()
  },
  beforeDestroy() {
    this.stopMonitoring()
  }
}
</script>
