<template>
  <div>
    <CustomerSearchInput label="Who?" v-model="customer" type="fieldAgent" />
    <EnrollFingerprints v-model="customer" v-if="customer" />
  </div>
</template>

<script>
import EnrollFingerprints from "@/components/EnrollFingerprints";
import CustomerSearchInput from "@/components/CustomerSearchInput";

export default {

  components: { EnrollFingerprints, CustomerSearchInput },

  data() {
    return {
      customer: undefined,
      valid: true,
    }
  },

  watch: {
  },

  methods: {
//    validate() {
//      this.$refs.fingerprintForm.validate();
//    },
  },

}
</script>