
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" v-if="customer">
        <Widget title="Basics">
          <v-simple-table>
            <tbody>
              <Row title="Name"      :text="approval.customer_name" bold="true" />
              <Row title="Telephone" :msisdn="customer.msisdn" />
              <Row title="Alternative Number" :msisdn="customer.alternative_msisdn" />
              <Row title="Email" :text="customer.email" />
              <Row title="National ID" :text="customer.demographic.identity.number" />
            </tbody>
          </v-simple-table>
        </Widget> 
      </v-col>
      <v-col cols="6" v-if="customer">
        <widget title="Duplicates" >
          <Duplicates  v-model="customer" />
        </widget>
      </v-col>
      <v-col cols="6" v-if="customer">
        <Widget title="Personal Details">
          <v-simple-table v-if="customer.demographic">
            <tbody>
              <Row title="Gender"       :gender="customer.demographic.gender"/>
              <Row title="Marital Status"      :text="customer.demographic.marital_status || customer.demographic.maritalStatus"/>
              <Row title="Age"          :number="customer.demographic.age" />
              <Row title="Born"          :date="customer.demographic.birthdate"/>
            </tbody>
          </v-simple-table>
        </Widget> 
      </v-col>

      <v-col cols="6" v-if="customer">
        <Widget title="Next of Kin">

          <v-simple-table v-if="customer.demographic.relations">
            <tbody>
              <Row title="Who"      :text="customer.demographic.relations.first.name" bold="true" />
              <Row title="Relation" :msisdn="customer.demographic.relations.first"  />
              <Row title="Telephone"  :text="customer.demographic.relations.first"  />
            </tbody>
          </v-simple-table>
          <h6 v-else>No next of kin details availed</h6>
        </Widget>
      </v-col>

      <v-col cols="6">
        <Widget title="Approval" v-if="approval">
          <v-simple-table>
            <tbody>
              <Row title="Created"   :time="approval.created_at" />
              <Row title="Request"   :text="approval.description" />
              <Row title="Status"    :status="approval.status"  />
            </tbody>
          </v-simple-table>
        </Widget>
      </v-col>
    </v-row>


    <v-row v-if="images">
      <v-col cols="12">
        <div no-gutters v-if="images && images.length>0">
          <Images :images="images" />
        </div>
      </v-col>
    </v-row>

  </v-container>

</template>


<script>
  import Widget from '@/components/Widget';
  import Row from "@/components/Row";
  import Images from "@/components/Images";
  import Duplicates from "@/views/contacts/partners/ultimate_tz/Duplicates";

  export default {

    components: { Widget, Row, Images, Duplicates },

    props: ['approval'],

    computed: {
      customer() { return this.$store.getters.customer(this.approval.customer_uuid || this.approval.customerUuid); },
      images() {
        let i = undefined;
        if (this.customer) {
          i = this.$store.getters.images.filter( e => ( e.type_id===this.customer.uuid ) && (e.type==='customer' || e.type==='demographic') );
          if (this.customer.demographic && this.customer.demographic.imageUrls) { i = i.concat(this.customer.demographic.imageUrls); }
          if (this.customer.demographic && this.customer.demographic.idScanUrls) { i = i.concat(this.customer.demographic.idScanUrls); }
          if (this.customer.imageUrls) { i = i.concat(this.customer.imageUrls) }
        }
        return i;
      },
    },
  }
</script>
