<template>
  <v-form v-model="valid">

    <v-row>
      <v-col cols="12">
        <FingerprintAuthorisation v-model="fingerprint" required label="Fingerprint (Required)" live/>
      </v-col>
    </v-row>

  </v-form>

</template>

<script>

import FingerprintAuthorisation from "@/components/FingerprintAuthorisation";

export default {

  components: { FingerprintAuthorisation },

  data() {
    return {
      valid: false,
      fingerprint: undefined,
    }
  },

  watch: {
    fingerprint(to) {
      if (to) {
        if (to === 'null') {
          to = null
        }
        this.$emit('input', to);
      }
    }
  },

}
</script>
