<template>
  <div @click="signOut">
    <v-icon title="Sign Out" color="primary" dense left>mdi-logout</v-icon>
    <span class="primary--text">{{userName}} : {{" "}}</span>
    <span>{{ auth }}</span>
  </div>
</template>

<script>
  export default {
    computed: {
      userName() { return this.$store.getters.settings.name; },
      auth() { return this.$store.getters.apiKey }
    },
    methods: {
      signOut() { this.$store.dispatch('logout'); },
    }
  };
</script>
