<template>
  <v-autocomplete
    v-model="customer"
    :search-input.sync="search"
    :items="customers"
    item-text="name"
    :filter="filter"
    return-object
    :label="label"
    :rules="required ? [requiredRule] : []"
    prepend-icon="mdi-database-search"
    style="min-width: 100px; max-width: 100%;"
  />
</template>

<script>
import lookups from "@/mixins/lookups";

export default {

  props: {
    value: Object,
    required: Boolean,
    rules: Array,
    label: {
      type: String,
      default: 'Customer',
    },
    type: {
      type: String,
      default: 'Customer',
    },
  },

  data() {
    return {
      valid: false,
      customer: undefined,
      search: undefined,
      customers: [],
      requiredRule: v => !!v || 'Required',
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.customer = to; }
    },
    customer(to) { this.$emit('input', to); },
    search(to) { this.retrievecustomers(); },
  },

  methods: {
    async retrievecustomers () {
      this.remoteLookup('searchCustomer', { search: this.search, type: this.type }).then( results => this.customers = results );
    },
    filter(e) {
      let s = this.search;
      if (/^0\d+$/.test(s)) { s = s.replace(/^0/,''); }
      let pattern = new RegExp(s,'i');
      return (pattern.test(e.name) || pattern.test(e.msisdn) || pattern.test(e.partner_identifier) || pattern.test(e.identity_number));
    },
  },

  mixins: [lookups],

}
</script>
