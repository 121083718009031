<template>
  
    <ServerSideResult :test="transactions">
      <v-data-table
        :headers="headers"
        :items="transactions"
        :items-per-page="25"
        class="elevation-1"
      >
      
        <template v-slot:item.at="{ item }">
          <Time v-if="item.at" :time="item.at" />
        </template>
      
        <template v-slot:item.amount="{ item }">
          <Currency v-if="item.amount" :value="item.amount" cents />
        </template>

        <template v-slot:item.after="{ item }">
          <Currency v-if="item.after" :value="item.after" cents />
        </template>
  
      </v-data-table>
  
    </ServerSideResult>
  
  </template>
  
  <script>
  import lookups from '@/mixins/lookups';
  
  import Time from "@/components/Time";
  import Currency from "@/components/Currency";
  import ServerSideResult from "@/components/ServerSideResult";
  
  
  export default {
  
    components: {  Time, Currency, ServerSideResult },
  
    props: {
      instrumentId: Number,
      instrument: Object,
    },
  
    data() {
      return {
        transactions: undefined,
        headers: [
          {
            text: 'When?',
            align: 'start',
            sortable: true,
            value: 'at',
          },
          {
            text: 'What?',
            align: 'start',
            sortable: true,
            value: 'type',
          },
          {
            text: 'Arrears Amount',
            align: 'end',
            sortable: true,
            value: 'amount',
          },
          {
            text: 'Arrears Balance',
            align: 'end',
            sortable: true,
            value: 'after',
          },
        ],
      }
    },
  
    computed: {
      id() { return this.instrumentId || this.instrument.id },
    },
  
    watch: {
    },
  
    methods: {
    },
  
    mounted() {
      this.remoteLookup('arrears_statement', { id: this.id }).then( txs => this.transactions = txs.transactions ).catch(e => this.transactions = null);
    },
  
    mixins: [lookups],
  
  }
  </script>
  