var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageHeader',{attrs:{"title":"Undisbursed Loans","icon":"mdi-play"}},[_c('ServerSideResult',{staticStyle:{"width":"100%"},attrs:{"test":_vm.wip}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.wip,"items-per-page":25},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('Time',{attrs:{"time":item.created_at}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.instrumentTypeToName(item.type))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('ToCustomer',{attrs:{"uuid":item.customer.uuid,"name":item.customer.name}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('Currency',{attrs:{"value":parseInt(item.amount)}}):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"dense":"","small":"","color":"primary","to":{name: 'UltimateTzLoanRequest', query: { uuid: item.customer.uuid, instrumentId: item.id } }}},[_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v("mdi-play")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }