<template>
  <SalaryAdvanceInput :customer="customer" :topup="instrument.id" :transfer="transfer" :arrears="instrument.arrears" />
</template>

<script>

import SalaryAdvanceInput from "@/views/partners/ultimate_tz/SalaryAdvanceInput";

export default {

  components: { SalaryAdvanceInput },

  props: {
    instrument: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
  },

  computed: {
    dueDate() { return new Date(this.instrument.due_at); },
    transfer() {
      // If the loan is increased more than ten days before the next salary payment date, no interest will be applied to the initial loan
      // Settlement amount include interest so back it out
      return (this.instrument.balance + this.instrument.tranfer_topup_interest)/100;
    },
  },

}
</script>
