<template>

  <v-container fluid>

    <v-row v-if="duplicates && value">
      <v-col cols="12">
        <v-simple-table dense>
          <template style="overflow:">
            <thead>
              <tr>
                <th class="text-left">Conflict</th>
                <th class="text-left">Match</th>
                <th class="text-left">Name</th>
                <th class="text-left">Telephone</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="conflict in duplicates" :key="conflict.customer_id" >
                <td class="text-left"><code>{{ conflict.check }}</code></td>
                <td class="text-left" v-if="conflict.check === 'duplicate_msisdn_check'"><Telephone :msisdn="conflict.match_data" /></td>
                <td class="text-left" v-if="conflict.check != 'duplicate_msisdn_check'">{{ conflict.match_data }}</td>
                <td class="text-left">{{ conflict.other_customer_name }}</td>
                <td class="text-left"><Telephone :msisdn="conflict.other_customer_msisdn" /></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>


    <v-row v-if="status=='not_enough_data'">
      <v-col cols="12">
        We need names and identity numbers to do this check.
      </v-col>      
    </v-row>

    <v-row v-if="status=='can_search'">
      <v-col cols="6" class="text-right">
        <!-- <v-btn color="warning" small dense @click="checkServerForDuplicates">
          Check Now
        </v-btn> -->
      </v-col>
      <v-col cols="6" class="text-left">
        <v-checkbox v-model="escalate" label="Ignore duplicates - escalate" />
      </v-col>
    </v-row>

    <v-row v-if="status=='searching'">
      <v-col cols="12">
        Please Wait: Checking...<br />
        <v-progress-circular indeterminate color="primary" :size="20" :width="2" />
      </v-col>
    </v-row>

    <v-row v-if="status=='offline'">
      <v-col cols="12">
        Offline
      </v-col>
    </v-row>

  </v-container>



</template>

<script>
import lookups from '@/mixins/lookups';

import Telephone from "@/components/Telephone";

export default {

  components: { Telephone },

  props: ['value'],

  data() {
    return {
      // customer: undefined,

      uuid: undefined,
      first_name: undefined,
      surname: undefined,
      identity_type: undefined,
      identity_number: undefined,
      msisdn: undefined,
      alternative_msisdn: undefined,

      duplicates: undefined,

      lookupRunning: false,

      escalate: false,
    };
  },

  computed: {
    status() {
      let s = 'not_enough_data';
      if (this.uuid && (((this.first_name || '') + (this.surname || '') + (this.identity_number || '') + (this.msisdn || '') + (this.alternative_msisdn || '')).length>4)) {
        if (this.duplicates) {
          s = 'can_search';
        } else {
          if (this.lookupRunning) {
            s = 'searching';
          } else {
            if (this.duplicates===null) {
              s = 'offline';
            } else {
              s = 'can_search';
            }
          }
        }
      }
      return s;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (to) {
          this.uuid = to.uuid;
          this.first_name = to.first_name;
          this.surname = to.surname;
          if (to.identity) {
            this.identity_type = to.identity.type;
            this.identity_number = to.identity.number;
          }
          this.msisdn = to.msisdn;
          this.alternative_msisdn = to.alternative_msisdn;
          let customer = this.$store.getters.customer(this.uuid)
          if (customer) {
            this.get_duplicates(customer)
          }
        }
        
      }
    },

    duplicates(to) {
      if (to.length==0) { this.escalate = false; }
      this.$emit('valid', (this.escalate || to.length==0));
    },

    escalate(to) {
      this.$emit('valid', (to || (this.duplicates || []).length==0));
    },

    first_name() { this.duplicates=undefined; },
    surname() { this.duplicates=undefined; },
    identity_type() { this.duplicates=undefined; },
    identity_number() { this.duplicates=undefined; },

  },

  methods: {
    validate() {},

    checkServerForDuplicates() {
      if (this.status=='can_search') {
        let params = { uuid: this.uuid }
        this.remoteLookup('duplicate_check_three', params).then( duplicates => this.duplicates = duplicates ).catch(e => this.duplicates = null);
      }
    },

    get_duplicates(customer) {
      let params = { uuid: customer.uuid }
      this.remoteLookup('duplicate_check_three', params).then( duplicates => this.duplicates = duplicates ).catch(e => this.duplicates = null);
    }
  },


  mixins: [lookups],

}
</script>
