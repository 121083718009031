<template>
  <div>
    <v-row v-if="status==='another_account_exists'" ><v-col justify="center" align="center"><span class="red--text">Client has another account enrolled with his fingerprints and cant enroll again</span></v-col></v-row>
    <v-row v-else-if="status==='enrolled'"><v-col justify="center" align="center"> <span class="text-h5 green--text"> Client Enrolled Successfully </span> </v-col></v-row>
    <EnrollFingerprints v-model="customer" @fingerprintStatus="fingerprintStatus" @done="done" v-else/>
  </div>
</template>

<script>
import EnrollFingerprints from "@/components/EnrollFingerprints";

export default {

  components: { EnrollFingerprints },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: false,
      status: undefined,
    }
  },

  computed: {
    getStatus() {
      this.status = this.value.fingerprintStatus
      if (this.status === "enrolled") {
        this.$emit('valid', true)
      }
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.customer = to;
        this.status = to.fingerprintStatus
      }
    },

    status(to) {
      if(to === "another_account_exists") {
        this.customer.fingerprintStatus = to
        this.$store.dispatch('upsertCustomer', this.customer);
        this.$emit('error', false)
      } else if (to === 'enrolled') {
        this.customer.fingerprintStatus = to
        this.$store.dispatch('upsertCustomer', this.customer);
        this.$emit('valid', true)
      }
    },
    
    customerFingerprintStatus(to) {
      this.status = to
    }
    // valid(to) { this.$emit('valid', to); },
  },

  methods: {
    validate() {
    },

    fingerprintStatus(to) {
      this.status = to
    },

    done() {
      this.$emit('valid', true)
    }
    
  },

}
</script>

