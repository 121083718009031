var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ServerSideResult',{attrs:{"test":_vm.actions}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.actions,"item-key":"action"},scopedSlots:_vm._u([{key:"item.follow_up_at",fn:function(ref){
var item = ref.item;
return [(item.follow_up_at && item.closed_at)?_c('Time',{staticClass:"grey--text",attrs:{"time":item.follow_up_at}}):(item.follow_up_at && !item.closed_at)?_c('Time',{attrs:{"time":item.follow_up_at}}):(item.created_at)?_c('Time',{staticClass:"grey--text",attrs:{"time":item.created_at}}):_vm._e()]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.activity))])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.created_by))])]}},{key:"item.closed_at",fn:function(ref){
var item = ref.item;
return [(item.closed_at)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-bold")]):_c('v-checkbox',{on:{"change":function($event){return _vm.toggle(item.uuid)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }