<template>
  <v-input :rules="[requiredRule]" v-model="authCode" class="pa-2" style="width: 100%;">
    <v-card style="width: 100%;" :loading="!authCode" loader-height="4" elevation="4" :color="errorColour">

      <template slot="progress">
        <v-progress-linear color="red"     indeterminate v-if="!authCode && !sample && acquisitionStarted"></v-progress-linear>
        <v-progress-linear color="primary" indeterminate v-if="!authCode && sample"></v-progress-linear>
      </template>

      <!-- <v-img height="220px" :src="image" v-if="image" /> -->
      <v-icon v-if="done" class="h1">mdi-fingerprint</v-icon>
      <v-img height="220px" v-if="acquisitionStarted && !image" />

      <v-card-actions>
        <v-btn :color="done ? 'green' : 'error'" block @click="capture" :disabled="done">
          {{done ? "CAPTUERED" : "CAPTURE"}}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-input>
</template>


<script>
  import prodDebug from "@/mixins/prodDebug";
  import lookups from "@/mixins/lookups";
  import { Base64 } from '@digitalpersona/core';
  import VInput from 'vuetify/lib/components/VInput/VInput.js';
  import CustomerSearchInput from "@/components/CustomerSearchInput";
  import { FingerprintReader, SampleFormat } from '@digitalpersona/devices';

  export default {
    components: { CustomerSearchInput },
    props: {
      value: {
        type: String,
        required: false,
      },
      user: {
        type: Boolean,
        default: true,
      },
      customer: {
        type: Object,
        required: false,
      },
      msisdn: {
        type: String,
        required: false,
      },
      live: {
        type: Boolean,
        default: false,
      },
      picked: {
        type: Object,
        required: false,
      },
    },

    data: () => ({
      sample: undefined,
      unavailable: false,
      connected: false,
      reader: undefined,
      acquisitionStarted: false,
      authCode: undefined,
      done: false
    }),

    computed: {
      image() { return (this.sample ? "data:image/png;base64," + Base64.fromBase64Url(this.sample) : undefined); },
      strategy() {
        let  r = undefined;
        if (this.user) {
          if (this.customer) {
            r = 'customer'
          } else {
            r = 'user';
          }

        } else {
          if (this.customer) {
            r = 'customer';
          } else if (this.msisdn) {
            r = 'msisdn';
          }
        }
        return r;
      },
      failed() { return (this.authCode===null); },
      errorColour() { return ( this.failed ? 'red lighten-4' : null ) },
      progressColour() { return (this.sample ? 'success' : 'red') },
    },

    watch: {
      authCode(to) {
        this.$emit('input', JSON.stringify(to));
        if (!to) {
          this.sample = undefined;
          // this.startCapture();
        }
      },
      sample(to) {
        console.log('acquired');
        if (this.sample) {
          // let params = {
          //   sample: Base64.fromBase64Url(this.sample),
          //   strategy: this.strategy,
          //   msisdn: this.msisdn,
          //   customer_uuid: (this.picked || this.customer || {}).uuid,
          //   live: this.live,
          // }
          // this.remotePost('fingerprint_auth', params).then( authCode => { this.authCode = authCode.authCode } ).catch(e => this.authCode = null);
        }
      },
    },

    methods: {

      cheat() {
        this.sample = (new Date()).getTime().toString();
        let params = {
            sample: this.sample,
            strategy: this.strategy,
            msisdn: this.msisdn,
            customer_uuid: (this.picked || this.customer || {}).uuid,
            live: this.live,
          }
          this.done = true;
          this.remotePost('fingerprint_auth', params).then( authCode => { this.authCode = authCode.authCode } ).catch(e => this.authCode = null);
      },

      requiredRule(value) {
        let r = [
          v => !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },


      capture() {
        console.log("strategy: ", this.strategy)
        console.log("consoltant: ", this.customer)
        fetch("http://localhost:15896/api/CloudABISV12Captures/Fingerprint", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
                  "CaptureType": "SingleCapture",
                  "SingleCaptureMode": "RightFingerCapture",
                  "QuickScan": "DISABLE",
                  "CaptureOperationName": "VERIFY",
                  "DeviceName": "DigitalPersona",
                  "CaptureTimeOut": 120,
                  "LeftFingerPosition": "RightThumb",
                  "RightFingerPosition": "RightThumb"
                })
        }).then(response => response.json())
        .then(response =>{
          let base64 = response.Images.Fingerprint[0].Base64Image
          let params = {
            sample: base64,
            strategy: this.strategy,
            msisdn: this.msisdn,
            customer_uuid: (this.picked || this.customer || {}).uuid,
            live: this.live,
          }
          this.done = true;
          this.remotePost('fingerprint_auth', params).then( authCode => { this.authCode = authCode.authCode } ).catch(e => this.authCode = null);

        }).catch(error => console.log("this is error: ", error))
      }
    },



    mounted () {
      if (this.production) {
        console.log('start scanning');
        // this.capture();
      }
      
    },

    mixins: [prodDebug, lookups],

  }
</script>