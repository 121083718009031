<template>
  <v-input :rules="[requiredRule]" v-model="fingerprint" class="pa-2" style="width: 100%;">
    <v-card style="width: 100%;" :loading="loading" loader-height="4" elevation="4">
      <template slot="progress">
        <v-progress-linear v-if="!done" color="red" indeterminate></v-progress-linear>
      </template>
      <v-card-title :class="{ 'green--text': done }">{{$t(finger)}}</v-card-title>
      <v-icon v-if="done" class="h1">mdi-fingerprint</v-icon>
      <v-row><v-col justify="center" align="center">
        <v-progress-circular v-if="loader" indeterminate color="green" :size="40" :width="5"></v-progress-circular>
        <span v-if="loader"> loading Please wait </span>
      </v-col></v-row>
      <!-- <v-img height="100" :src="image" @click="captured" /> -->
      <v-card-actions>
        <v-checkbox v-model="unavailable" label="Unavailable" @change="captured" :disabled="!loading" />
      </v-card-actions>
    </v-card>
  </v-input>
</template>


<script>
  import { v4 as uuidv4 } from 'uuid';
  import { Base64 } from '@digitalpersona/core';
  import ImageDb from '@/store/ImageDb';
  import lookups from "@/mixins/lookups";
  import VInput from 'vuetify/lib/components/VInput/VInput.js';

  export default {

    props: {
      value: {
        value: Object,
      },
      customer: {
        value: Object,
        required: true,
      },
      hand: {
        value: String,
        required: Boolean,
        validator(value) { return ['left', 'right'].includes(value); },
      },
      finger: {
        value: String,
        required: Boolean,
        validator(value) { return ['index', 'middle', 'ring', 'little', 'thumb'].includes(value); },
      },
      fingerNumber: Number,
      available_fingers: Number,
      loading: Boolean,
    },

    data: () => ({
      fingerprint: false,
      uuid: uuidv4(),
      sample: undefined,
      unavailable:false,
      done: false,
      new_finger_number: undefined,
      status: undefined,
      loader: false,
      statusList: [],
    }),

    computed: {
      image() { return (this.sample ? "data:image/png;base64," + Base64.fromBase64Url(this.sample) : undefined); },
      finger_checker() {
        if (this.unavailable) {
          this.new_finger_number = this.available_fingers - 1 
        }
        return (this.new_finger_number)
      },
    },

    watch: {
      
      status: {
        immediate: true,
        handler(to) {
          if (this.customer  && this.customer.demographic['isFingerprintEnrolled?'] === true) {
            this.customer.fingerprintStatus = 'enrolled'
            this.$store.dispatch('upsertCustomer', this.customer);
          }
          if (to === 'another_account_exists') {
            this.customer.fingerprintStatus = 'another_account_exists'
            this.$store.dispatch('upsertCustomer', this.customer);
          }
          this.statusList.push(to)
        }
      },

      value: {
        immediate: true,
        handler(to) {
          this.statusList = to
        }
      },

      statusList: {
        immediate: true,
        handler(to) {
          this.$emit('input', to)
        }
      },
        
      
    },

    methods: {

      fingerprintCaptured(sample) {
        this.sample = sample;
        this.captured();
        this.saveFingerprint();
      },

      captured() {
        if(this.image || this.unavailable) {
          this.$emit('captured', this.fingerNumber, this.finger_checker);
        }
       },

      capture(hand, finger) {
        if (!this.unavailable) {
          fetch("http://localhost:15896/api/CloudABISV12Captures/Fingerprint", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                    "CaptureType": "SingleCapture",
                    "SingleCaptureMode": hand + "FingerCapture",
                    "QuickScan": "DISABLE",
                    "CaptureOperationName": "Enroll",
                    "DeviceName": "DigitalPersona",
                    "CaptureTimeOut": 120,
                    "LeftFingerPosition": (hand + finger),
                    "RightFingerPosition": (hand + finger)
                  })
          }).then(response => response.json())
          .then(response =>{
            if(response["CloudScanrStatus"]["Success"] === true) {

              let base64 = response.Images.Fingerprint[0].Base64Image
              let params = {
                sample: base64,
                strategy: this.strategy,
                finger: finger.toLocaleLowerCase(),
                hand: hand.toLocaleLowerCase(),
                msisdn: this.msisdn,
                customer_uuid: this.customer.uuid,
                live: this.live,
                available_fingers: this.available_fingers
              }

              this.sample = base64
              if (this.production){
                this.saveFingerprint();
              } else{
                this.remotePost('fingerprint_enrol', params)
                .then( authCode => {
                   this.status = authCode.status;
                   this.authCode = authCode.authCode
                   this.done = true
                   this.loader = false
                   if(authCode.status != undefined) {
                    this.$emit('captured', this.fingerNumber, this.available_fingers);
                   }
                  } ).catch(e => { this.authCode = null});
                  if (this.fingerNumber === 9) {
                    this.loader = true
                  }
               }
            }
            
          }).catch(error => console.log("this is error: ", error))

        } else {
          this.$emit('captured', this.fingerNumber, this.available_fingers);
        }
        
      },


      saveFingerprint() {
        let uuid = ImageDb.add(Base64.fromBase64Url(this.sample));  
        this.$store.dispatch('addImage', { type: 'fingerprint', type_id: this.customer.uuid, uuid: uuid, hand: this.hand, finger: this.finger, available_fingers: this.available_fingers });
        this.$emit('click', this.fingerNumber, uuid);
      },

      requiredRule(value) {
        let r = [
          v => !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },

    mounted() {
      this.remoteLookup('fingerprint_image_get', { uuid: this.customer.uuid, hand: this.hand, finger: this.finger }).then( detail => console.log(detail) );
    },

    mixins: [lookups],

  }
</script>

<style scoped>
  .border_line {
    
  }
</style>



<i18n>
{
  "en": {
    "little": "Little Finger",
    "ring": "Ring Finger",
    "middle": "Middle Finger",
    "index": "Index Finger",
    "thumb": "Thumb"
  },
  "es": {
    "index": "Hombre",
    "feindex": "Mujer"
  },
  "sw": {
    "index": "Mwanaume",
    "feindex": "Mwanamke"
  }
}
</i18n>
