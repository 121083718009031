<template>

  <v-data-table dense :headers="headers" :items="theActions" item-key="action" class="elevation-1" style="width: 100%;">

    <template v-slot:item.created_at="{ item }">
      <Time :time="item.created_at" />
    </template>

    <template v-slot:item.activity="{ item }">
      <code>{{item.activity}}</code>
    </template>

    <template v-slot:item.customer_name="{ item }">
      <ToCustomer :uuid="item.customer_uuid" :name="item.customer_name"/>
    </template>

    <template v-slot:item.follow_up_at="{ item }">
      <Time :time="item.follow_up_at" />
    </template>

    <template v-slot:item.closed_by="{ item }">
      <v-icon color="success" v-if="item.closed_by">mdi-check-bold</v-icon>
    </template>

    <template v-slot:item.closed_at="{ item }">
      <v-icon color="success" v-if="item.closed_at">mdi-check-bold</v-icon>
    </template>

  </v-data-table>
    
</template>


<script>
  import actionNames from '@/mixins/actionNames';
  import Time from "@/components/Time";
  import ToCustomer from "@/components/ToCustomer";

  export default {

    components: { Time, ToCustomer },

    props: {
      actions: {
      	type: Array,
      	required: true,
      },
    },

    data: () => ({
      headers: [
        {
          text: 'Created',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Activity',
          align: 'start',
          sortable: true,
          value: 'activity',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'description',
        },
        {
          text: 'Customer',
          align: 'start',
          sortable: true,
          value: 'customer_name',
        },
        {
          text: 'Notes',
          align: 'start',
          sortable: false,
          value: 'note',
        },
        {
          text: 'Follow Up',
          align: 'start',
          sortable: true,
          value: 'follow_up_at',
        },
        {
          text: 'Created By',
          align: 'start',
          sortable: true,
          value: 'created_by',
        },
        {
          text: 'Done',
          align: 'center',
          sortable: true,
          value: 'closed_at',
        },
      ],
    }),

    computed: {
      theActions() { return this.actions.map( e => {
      	e.description = this.actionTypeToName(e.activity);
      	return e
      })},
    },

    mixins: [actionNames],

  }
</script>
