<template>
  <v-form ref="testForm" v-model="validForm">
    <v-container fluid>
      <div>
        Verify: check if the biometric fingerprint of a person matches <br/>
        that of fingerprint on the system that corresponds<br/>
        to the person if the person is on the system
      </div>
      <v-row>
        <v-col cols="6" class="text-left">

          <div>
            <v-radio-group v-model="strategy" row >
              <v-radio label="Logged In Agent" value="user" />
              <v-radio label="Customer" value="customer" />
              <v-radio label="MSISDN" value="msisdn" />
              <v-radio label="Search" value="searched customer" />
            </v-radio-group>
          </div>

          <div v-if="strategy">Strategy: <code>{{strategy}}</code></div>

          <div v-if="strategy=='user'">
            Authorise Based on the current user.<br/>
            Use the prop <code>user</code>.
          </div>
          <div v-if="strategy=='customer'">
            Authorise based on a user. To test we pass the current user ({{userName}}).<br/>
            Pass in the <code>customer</code>.
          </div>
          <div v-if="strategy=='msisdn'">
            Authorise using an MSISDN ({{msisdn}}).<br/>
            <MsisdnInput v-model="msisdn"/>
          </div>
          <div v-if="strategy=='searched customer'">
            <CustomerSearchInput label="Search Customer" v-model="customer_selected"/>
          </div>
        </v-col>

        <v-col cols="3">
          <FingerprintAuthorisation v-model="form.authorisation" :customer="customer" :msisdn="msisdn" :user="strategy=='user'" :action="action='fingerprint_auth'" :picked="pickedCustomer" live />
        </v-col>

        <v-col cols="3" class="text-left">
          <div class="text-h5">Result</div>
          <div>
            <ServerSideResult :test="form.authorisation">
              {{form.authorisation}}
            </ServerSideResult>
          </div>
        </v-col>

      </v-row>


      <v-row>
        <v-col class="text-right">
          <v-btn color="warning" small dense class="mr-1" @click="this.validate">
            {{$t('validate')}}
          </v-btn>
          <v-btn color="warning" small dense @click="this.saveForm" :disabled="!validForm">
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-form>
</template>

<script>
import FingerprintAuthorisation from "@/components/FingerprintAuthorisation";
import MsisdnInput from '@/components/MsisdnInput';
import ServerSideResult from '@/components/ServerSideResult';
import CustomerSearchInput from "@/components/CustomerSearchInput";

export default {

  components: {
    FingerprintAuthorisation,
    MsisdnInput,
    ServerSideResult,
    CustomerSearchInput,
  },

  data() {
    return {
      validForm: false,
      form: {},
      msisdn: '27733651998',
      strategy: 'user',
      action: 'fingerprint_auth',
      pickedCustomer: undefined,
      customer_selected: undefined,
    }
  },

  computed: {
    userName() { return this.$store.getters.settings.name; },
    uuid() { return this.$store.getters.settings.fieldAgentUuid; },
    partnerCode() { this.$store.getters.settings.partnerCode; },
    telephoneCode() { return this.$store.getters.settings.telephoneCode; },
    customer() { return (( this.strategy==='searched customer' ? { uuid: (this.customer_selected || {}).uuid } : undefined) || (this.strategy==='customer' ? { uuid: this.uuid } : undefined )); },
  },

  methods: {
    validate(event) {
      this.validating = true;
      this.$refs.testForm.validate();
    },
    saveForm(event) {
      console.log('saveForm', event)
    }
  },

  mounted() {
    this.msisdn = `${this.telephoneCode}833939595`;
  }

}
</script>

<style>
.header {
  font-size: 15px;
  font-weight: bold;
  margin-top: 50px;
}
</style>
