<template>
  <v-container fluid>
    <v-card>
      <v-carousel v-if="images.length!=0" v-model="model">
        <v-carousel-item v-for="image in images" light>
          <v-sheet height="100%" tile >
            <v-row class="fill-height" align="center" justify="center">
              <SingleImage :image="image" />
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-card>
    <v-row justify="center">
      <v-col
        v-for="doc in documents"
        :key="doc.uuid"
        cols="auto"
      >
        <v-card
          elevation="5"
          height="180"
          width="180"
        >
          <p class="py-5">
            <p>{{doc.documentName || doc.name}}.pdf</p>
            <Time :time="doc.createdAt"/>
          </p>
          <v-icon @click="downloadPdf(doc.url)" x-large dense color="red">mdi-arrow-down-bold-box-outline</v-icon>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import SingleImage from '@/components/SingleImage';
  import Time from '@/components/Time';

  export default {
    components: { SingleImage, Time },

    props: ['images', 'documents'],

    data: () => ({
      model: 0,
    }),
    methods: {
      downloadPdf(url) {
        window.open(url, '_blank');
      }
    }

    // watch: {
    //   images: {
    //     immediate: true,
    //     handler(to) {
    //       this.srcs = [];
    //       this.images.forEach(image => {
    //       if (typeof(image)==='string') {
    //         this.srcs.push(image.url);
    //       } else if (image.uuid) {
    //         ImageDb.url(image.uuid).then( e => this.srcs.push(e) );
    //       }
    //     })},
    //   }
    // }
  }
</script>