<template>
  <v-container fluid>

    <v-row>
      <v-col cols="6">
        <Widget>
          <CustomerDetails :customer="customer" />
        </Widget>
      </v-col>

      <v-col cols="6">
        <v-row>
          <v-card max-width="65%" class="mx-auto">
            <SingleImage v-if="customerImages" :image="customerImages" />
          </v-card>        
        </v-row>
        <v-row mb="8">
          <Widget>
            <KinInfo :customer="customer" />
          </Widget>
        </v-row>
        <v-row>
          <Widget title="Actions">
            <CustomerActions :customer="customer" ref="customerActions" />
          </Widget>
        </v-row>  
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import CustomerDetails from '@/views/customers/partners/ultimate_tz/components/CustomerDetails'
import Widget from "@/components/Widget";
import KinInfo from '@/views/customers/partners/ultimate_tz/components/KinInfo'
import CustomerActions from "@/views/actions/CustomerActions"
import SingleImage from "@/components/SingleImage";

export default {

  components: { CustomerDetails, Widget, CustomerActions, KinInfo, SingleImage },

  props: {
    customer: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
    }
  },

  computed: {
    customerImages() {
      return ((this.customer.imageUrls || []).at(-1))
    }
  },

  watch: {

  },

}
</script>
