<template>
  <div>
    <v-form ref="fingerprintForm" class="text-left">
      <v-row v-if="status==='another_account_exists'" ><v-col justify="center" align="center"><span class="red--text">Client has another account enrolled with his fingerprints and cant enroll again</span></v-col></v-row>
      <v-row v-else-if="status==='enrolled'"><v-col justify="center" align="center"> <span class="text-h5 green--text"> Client Enrolled Successfully </span> </v-col></v-row>
      <v-row v-else-if="customer  && customer.demographic['isFingerprintEnrolled?'] === true" ><v-col justify="center" align="center"> <span class="red--text">This person is alread Enrolled you cant enroll again </span> </v-col></v-row>
      <v-container v-else>
    
        <v-row>
          <v-col class="text-h4">
            Left Hand
          </v-col>
        </v-row>
    
        <v-row>
          <v-col cols="12" md="2" v-for="(finger, index) in ['little', 'ring', 'middle', 'index', 'thumb']">
            <EnrollFingerprint v-model="statusList" :customer="customer" hand="left" :finger="finger" :fingerNumber="index" :loading="fingerNumber===index" :ref="`finger-${index}`" @captured="captured" :available_fingers="available_fingers" @loading="loading"/>
          </v-col>
        </v-row>
    
        <v-row>
          <v-col class="text-h4">
            Right Hand
          </v-col>
        </v-row>
    
        <v-row>
          <v-col cols="12" md="2" v-for="(finger, index) in ['thumb','index','middle','ring','little']">
            <EnrollFingerprint v-model="statusList" :customer="customer" hand="right" :finger="finger" :fingerNumber="index + 5" :loading="fingerNumber===(index + 5)" :ref="`finger-${(index + 5)}`" @captured="captured" :available_fingers="available_fingers" @loading="loading"/>
          </v-col>
        </v-row>
    
        <v-row>
          <v-col cols="12" md="2">
            <v-btn id="start" @click="startCapture">start</v-btn>
            <!-- <v-btn id="start" @click="onSamplesAcquired">test</v-btn> -->
          </v-col>
        </v-row>
    
      </v-container>
      
  
    </v-form>  
  </div>
</template>

<script>
import prodDebug from "@/mixins/prodDebug";
import EnrollFingerprint from "@/components/components/EnrollFingerprint";

export default {

  components: { EnrollFingerprint },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: true,
      reader: undefined,
      acquisitionStarted: false,
      captured_data: undefined,

      fingerNumber: 0,
      available_fingers: 10,
      loader: false,
      status: undefined,
      statusList: [],

      left: {},
      right: {},
    }
  },

  computed: {
    getStatus() {
      if(this.status === undefined) {
        this.status = this.value.fingerprintStatus
      }
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.customer = to;
        this.status = to.fingerprintStatus
      }
    },
    statusList: {
      immediate: true,
      handler(to) {
        this.status = to.filter(e=> e != 'initialised')[0]
      }
    },

    status(to) {
      if(to) {
        this.loader = false
      }
      this.$emit('fingerprintStatus', to)
    },
    customer(to) { this.$emit('input', to); },
  },

  methods: {

    captured(fingerNumber, available_fingers) {
      this.available_fingers = available_fingers
      console.log(fingerNumber);
      this.fingerNumber = (fingerNumber || 0) + 1;
      if (this.fingerNumber==10) { this.loader = true; this.$emit('done') }
      let vue_object = this.$refs[`finger-${this.fingerNumber}`]
      if (vue_object) {
        vue_object = vue_object[0]
        let hand = vue_object.hand === "left" ? "Left" : "Right"
        let finger = vue_object.finger.charAt(0).toUpperCase() + vue_object.finger.slice(1);
        vue_object.capture(hand, finger);
      }
    },

    loading() {
      this.loader = true
    },

    validate() {
      this.$refs.fingerprintForm.validate();
    },

    startCapture() {
      let vue_object = this.$refs[`finger-${this.fingerNumber}`][0]
      let hand = vue_object.hand === "left" ? "Left" : "Right"
      let finger = vue_object.finger.charAt(0).toUpperCase() + vue_object.finger.slice(1);
      vue_object.capture(hand, finger)
    },

  },

  mounted () {
    console.log('start scanning');
    // this.startCapture();
    
  },

  mixins: [prodDebug],

}
</script>
