<template>
  <v-container fluid>
    <v-row>

      <v-col cols="9">
        <Widget title="Transaction History">
          <CustomerFacingTransactions :instrument="instrument" />
        </Widget>
      </v-col>

      <v-col cols="3">

        <Value label="Loan Number" v-if="instrument.partner_identifier" class="mb-4">
          <code>{{instrument.partner_identifier}}</code>
        </Value>

        <Value label="Status" v-if="instrument.partner_status" class="mb-4">
          <code>{{instrument.partner_status}}</code>
        </Value>

        <Date label="Next Due" :value="instrument.due_at" v-if="instrument.due_at" class="mb-4" />

        <Percentage label="Rate" :value="parseFloat(instrument.interest_rate)" v-if="instrument.interest_rate" class="mb-4" />

        <v-sheet :elevation="2" :rounded="true" class="pa-2 mb-4" >
          <div class="d-flex flex-column text-caption">
            <div>
              <v-btn color="primary" text :href="`${domain}/ultimate_tz/documents/contract/${uuid}?${contractQs}`" target="_blank">Contract</v-btn>
              <v-btn color="primary" text :disabled="!(customer.branch_name) || !(customer.account_number)" :href="`${domain}/ultimate_tz/documents/direct_debit/${uuid}?${ddQs}`" target="_blank">DD</v-btn>

              <v-btn color="primary" text :href="`${domain}/ultimate_tz/documents/demand_letter/${uuid}?${qsSettlement}`" target="_blank">Demand</v-btn>
              <v-btn color="primary" text :href="`${domain}/ultimate_tz/documents/settlement_quote/${uuid}?${qsSettlement}`" target="_blank">Quote</v-btn>

            </div>
            <div class="text-caption">Print</div>
          </div>
        </v-sheet>

      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import prodDebug from "@/mixins/prodDebug";

import Widget from "@/components/Widget";
import CustomerFacingTransactions from "@/views/instruments/CustomerFacingTransactions"
import Date from "@/views/instruments/components/Date"
import Value from "@/views/instruments/components/Value"
import Currency from "@/components/Currency"
import Percentage from "@/views/instruments/components/Percentage"

export default {

  components: { Widget, CustomerFacingTransactions, Date, Value, Currency, Percentage },

  // value is (maybe) and instrument
  props: {
    instrument: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
  },

  computed: {
    uuid() { return this.customer.uuid; },
    contractQs() {
      let qs = { instrument_id: this.instrument.id };
      return new URLSearchParams(qs).toString();
    },
    userName() { return this.$store.getters.settings.name; },
    qsSettlement() { return new URLSearchParams({ agent_name: this.userName, settlement_amount: (this.instrument.settlement_amount || 0), due_at: this.instrument.due_at }).toString(); },
    ddQs() {
      let qs = { uuid: this.uuid };
      return new URLSearchParams(qs).toString();
    },
    domain() { return (this.production ? 'https://connect.sasa.solutions' : 'http://connect.sasa.local:3000'); },
  },

  mounted() {
    this.$parent.$emit('changeHighlight', 'Balance');
  },

  mixins: [prodDebug],

}
</script>
