<template>
    <v-form v-model="valid" ref="expenseForm" >
    
      <v-container fluid>

        <v-row>
          <v-col cols="4">
            <Widget title="Expense">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <SelectInput :items="expenseTypes" single-line v-model="expenseType" required />
                  </v-col>
                  <v-col cols="12">
                    <CurrencyInput v-model="amount" required />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="notes" label="Notes" solo />
                  </v-col>
                </v-row>
              </v-container>
            </Widget>
          </v-col>

          <v-col cols="4">
            <Widget title="Supporting Documentation">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <DocumentInput v-model="invoice" type="transactional" label="Receipt" required :typeId="uuid" dense />
                  </v-col>
                  <v-col cols="12">
                    <DocumentInput v-model="confirmation" type="transactional" label="Email Confirmation" :typeId="uuid" dense />
                  </v-col>
                </v-row>
              </v-container>
            </Widget>
          </v-col>

          <v-col cols="4">
            <Widget title="">
              <FingerprintAuthorisation v-model="authorisation" user live/>
            </Widget>
          </v-col>

        </v-row>

        <v-row>
          <v-col cols="2">
            <v-btn color="warning" dense block @click="validate">Validate</v-btn>
          </v-col>
          <v-col cols="10">
            <v-btn color="primary" block :disabled="!valid" @click="submit">Submit</v-btn>
          </v-col>
        </v-row>

      </v-container>
    </v-form>
</template>

<script>
import lookups from '@/mixins/lookups';

import PageHeader from "@/components/PageHeader";
import SelectInput from "@/components/SelectInput";
import CurrencyInput from "@/components/CurrencyInput";
import PhotoInput from "@/components/PhotoInput";
import DocumentInput from "@/components/DocumentInput";
import Widget from "@/components/Widget";
import FingerprintAuthorisation from '@/components/FingerprintAuthorisation';
import { v4 as uuidv4 } from 'uuid';


export default {

  components: { PageHeader, SelectInput, CurrencyInput, PhotoInput, DocumentInput, Widget, FingerprintAuthorisation },

  props: ['value'],

  data() {
    return {
      valid: false,
      expenseTypes: ['Computer expenses', 'Electricity & water', 'Entertainment', 'Internet', 'Legal fees', 'Licencing', 'Local transport', 'Marketing', 'Office cleanliness', 'Office consumables', 'Printing & stationery', 'Rates & refuse', 'Rent', 'Repairs & maintenance', 'Security', 'Telecommunications'],
      expenseType: undefined,
      amount: 0,
      invoice: undefined,
      authorisation: undefined,
      notes:undefined,
      uuid: uuidv4(),

      transactions: undefined,
      safeboxOwnerName: undefined,
      balance:  0,

      confirmation: undefined,
    }
  },

  computed: {
    result() {
      return {
        expenseType: this.expenseType,
        amount: this.amount,
        notes: this.notes,
        invoice: this.invoice,
        confirmation: this.confirmation,
        uuid: this.uuid
      };
    }
  },

  methods: {
    validate() {
      this.$refs.expenseForm.validate();
    },
    submit() {
      console.log(this.result);
      this.$emit('input', this.result);
    }
  },

  mixins: [lookups],

}
</script>
