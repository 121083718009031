<template>

  <PageHeader title="Undisbursed Loans" icon="mdi-play">
  
    <ServerSideResult :test="wip" style="width: 100%;">
      <v-data-table
        :headers="headers"
        :items="wip"
        :items-per-page="25"
        class="elevation-1"
      >
      
        <template v-slot:item.created_at="{ item }">
          <Time :time="item.created_at" />
        </template>
      
        <template v-slot:item.type="{ item }">
          {{instrumentTypeToName(item.type)}}
        </template>
      
        <template v-slot:item.customer="{ item }">
          <ToCustomer :uuid="item.customer.uuid" :name="item.customer.name"/>
        </template>


        <template v-slot:item.amount="{ item }">
          <Currency v-if="item.amount" :value="parseInt(item.amount)" />
        </template>
      
<!--         <template v-slot:item.status="{ item }">
          <v-btn dense small plain color="warning" @click="cancel(item)">
            Cancel
          </v-btn>
        </template> -->
      
        <template v-slot:item.id="{ item }">
          <v-btn dense small color="primary" :to="{name: 'UltimateTzLoanRequest', query: { uuid: item.customer.uuid, instrumentId: item.id } }">
            <v-icon dense small>mdi-play</v-icon>
          </v-btn>
        </template>

      </v-data-table>

    </ServerSideResult>

  </PageHeader>

</template>

<script>
import lookups from '@/mixins/lookups';
import instrumentNames from '@/mixins/instrumentNames';

import PageHeader from "@/components/PageHeader";
import Time from "@/components/Time";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";
import ToCustomer from "@/components/ToCustomer";


export default {

  components: { PageHeader, Time, Currency, ServerSideResult, ToCustomer },

  props: {
    customer: Object,
  },

  data() {
    return {
      wip: undefined,
      headers: [
        {
          text: 'Created',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Product',
          align: 'start',
          sortable: true,
          value: 'type',
        },
        {
          text: 'Customer',
          align: 'start',
          sortable: true,
          value: 'customer',
        },
        {
          text: 'Amount',
          align: 'end',
          sortable: true,
          value: 'amount',
        },
        // {
        //   text: '',
        //   align: 'center',
        //   sortable: true,
        //   value: 'status',
        // },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'id',
        },
      ],
    }
  },

  computed: {
  },

  watch: {
  },

  // methods: {
  //   cancel(item) {
  //     this.$delete(this.wip, this.wip.findIndex( e => e.id==item.id));
  //     this.remoteLookup('instruments_cancel_submission', { id: item.id }).then().catch(e => console.log(e));
  //   }
  // },

  mounted() {
    this.remoteLookup('instruments_open_not_disbursed', {}).then( wip => this.wip = wip.instruments ).catch(e => this.wip = null);
  },

  mixins: [lookups, instrumentNames],

}
</script>
