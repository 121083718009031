<template>
  <CheckMeBackInput :customer="customer" :topup="instrument.id" :transfer="instrument.topup_transfer/100" :arrears="instrument.arrears" />
</template>

<script>
import CheckMeBackInput from "@/views/partners/ultimate_tz/CheckMeBackInput";

export default {

  components: { CheckMeBackInput },

  props: {
    instrument: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
  },

}
</script>
