<template>
  
  <v-container fluid>

    <v-row>
      <v-col cols="12" class="text-h3 text--primary text-left">
        <span v-if="status=='eligible'">               Check Me Back of up to <Currency v-model="maxPrincipal" />          </span>
        <span v-else-if="status=='existing'">                                                                              </span>
        <span v-else-if="status=='doNotLend'">                                                                             </span>
        <span v-else-if="status=='pricing_agreed'">    Sign and Upload Loan Contract                                       </span>
        <span v-else-if="status=='loan_created'">                                                                          </span>
        <span v-else-if="status=='authorise'">         Cash Received                                                       </span>
        <span v-else-if="status=='concluded'">                                                                             </span>
      </v-col>
    </v-row>

    <div v-if="status=='initialised'">     <Eligibility  :customer="customer" v-model="loanParameters"  />                                                                   </div>
    <div v-if="status=='existing'">        <Existing     :customer="customer" :instrumentId="openLoanId" />                                                                  </div>
    <div v-if="status=='eligible'">        <Pricing      :customer="customer" v-model="loanRequest" :loanParameters="loanParameters"  @agreed="status='pricing_agreed'" />   </div>
    <div v-if="status=='pricing_agreed'">  <Documents    :customer="customer"                       :loanRequest="loanRequest"        @uploaded="status='docs_submitted'" /> </div>
    <div v-if="status=='docs_submitted'">  <LoanCreation :customer="customer" v-model="instrument"  :loanRequest="loanRequest"        @input="status='loan_created'" />      </div>
    <div v-if="status=='loan_created'">    <Approvals    :customer="customer" :instrument="instrument"                                @done="status='approved'" />           </div>
    <div v-if="status=='approved'">        <v-progress-circular indeterminate color="primary" />                                                                             </div>
    <div v-if="status=='authorise'">       <Authorise    :customer="customer"                                                         @input="status='authorised'" />        </div>
    <div v-if="status=='authorised'">      <Conclude     :customer="customer" :instrument="instrument"                                @done="status='concluded'" />          </div>
    <div v-if="status=='concluded'">       <ThankYou     :customer="customer" />                                                                                             </div>

  </v-container>

</template>

<script>
import lookups from '@/mixins/lookups';

import CMBInput from '@/views/partners/ultimate_tz/CheckMeBackInput.vue'

import Eligibility from '@/views/partners/ultimate_tz/check_me_back/Eligibility'
import DoNotLend from '@/views/partners/ultimate_tz/salary_advance/DoNotLend'
import Existing from '@/views/partners/ultimate_tz/check_me_back/Existing'
import Pricing from '@/views/partners/ultimate_tz/check_me_back/Pricing'
import Documents from '@/views/partners/ultimate_tz/check_me_back/Documents'
import LoanCreation from '@/views/partners/ultimate_tz/check_me_back/LoanCreation'
import Approvals from '@/views/partners/ultimate_tz/check_me_back/Approvals'
import Authorise from '@/views/partners/ultimate_tz/check_me_back/Authorise'
import Conclude from '@/views/partners/ultimate_tz/check_me_back/Conclude'
import ThankYou from '@/views/partners/ultimate_tz/check_me_back/ThankYou'


import Currency from "@/components/Currency";

export default {

  components: { CMBInput, Eligibility, Existing, DoNotLend, Pricing, Documents, LoanCreation, Approvals, Authorise, Conclude, ThankYou, Currency },

  props: ['customer'],

  data() {
    return {
      loanParameters: undefined,
      loanRequest: undefined,
      instrument: undefined,
      status: 'initialised',
      noNeedToload: true,
    }
  },

  computed: {
    maxPrincipal() { return ((this.loanParameters || {}).loan_amount || 0); },
    openLoanId() { return (this.loanParameters || {}).open_loan_id; },
    cash() {
      let c = 0;
      if (this.loanRequest && this.loanRequest.cash) {
        let i = parseInt(this.loanRequest.cash);
        if (!isNaN(i)) {
          c = i;
        }
      }
      if (c===0 && this.instrument && this.instrument.payload && this.instrument.payload.cash) {
        let i = parseInt(this.instrument.payload.cash);
        if (!isNaN(i)) {
          c = i;
        }
      }
      return c;
    },
  },

  watch: {
    status(to) {
      console.log("status: file checkmeback.vue", to)
      if (to=='approved') {
        if (this.cash>0) {
          this.status = 'authorise';
        } else {
          this.status = 'authorised';
        }
      }
    },
    loanParameters(to) {
      if (to && this.status=='initialised') {
        if (to.open_loan_id) {
          this.status = 'existing';
        } else if (to.doNotLend==true) {
          this.status = 'doNotLend';
        } else {
          this.status = 'eligible';
        }
      }
    },
    maxPrincipal(to) {
      if ( to && (to>0) && this.principal===0 ) { this.cash = this.maxPrincipal; }
    },
  },

  methods: {
    instrumentLoaded(instrument) {
      this.instrument = instrument;
      this.status = 'loan_created';
      this.noNeedToload = true;
    }
  },

  mounted() {
    if (this.$route.query.instrumentId) {
      this.noNeedToload = undefined;
      this.remoteLookup('instrument_ultimate_tz_check_me_back', { uuid: this.customer.uuid, id: this.$route.query.instrumentId }).then( instrument => this.instrumentLoaded(instrument) ).catch(e => this.instrument = null);
    }
  },

  mixins: [lookups],

}
</script>
