<template>
  <v-container fill-height fluid>

    <v-row align="center" justify="center">
      <v-col cols=1 />

      <v-col cols=10 v-if="showWarehouse">
        <v-text-field :placeholder="$t('warehousePlaceholder')" v-model="warehouseName"></v-text-field>
        <Restart class="mt-2" @restart="restart()" />
      </v-col>

      <v-col cols=10 v-else>

        <v-alert dense outlined type="error" v-model="problem" icon="mdi-emoticon-sad-outline" dismissible >
           {{$t('message')}}
        </v-alert>

        <v-alert dense outlined type="warning" v-model="invalidOtp" icon="mdi-emoticon-sad-outline" dismissible >
           {{$t('invalidOtp')}}
        </v-alert>
        <div class="d-flex flex-column"  v-if="identifier===undefined">
          <div class="my-2">
            <Heading large> Login using phone number </Heading>
            <Identifier v-model="identifier"/>
          </div>
          <div class="my-3">
            <Heading large> Login using your fingerprint</Heading>
            <FingerprintIdentification v-model="fingerprintLogin" loginCall required label="Fingerprint (Required)"/>
          </div>
        </div>
        
        <v-progress-circular indeterminate color="primary" v-else-if="key===undefined" />
        <Otp v-model="otp"  :identifier="identifier"       v-else-if="otp===undefined" />
        <v-progress-circular indeterminate color="primary" v-else-if="otp" />

        <Restart class="mt-2" v-if="identifier" @restart="restart()" />

      </v-col>


      <v-col cols=1 />
    </v-row>

    <v-snackbar bottom right :value="warehouseCreate" :timeout="-1" color="primary">
      {{$t('createNewWarehouse')}}
      <v-btn text @click="initCreateWarehouse">
        {{$t('new')}}
      </v-btn>
    </v-snackbar>

  </v-container>
</template>

<script>
  import domain from '@/mixins/domain';
  import lookups from '@/mixins/lookups';
  import Heading from '@/components/Heading';
  import Identifier from '@/views/login/components/Identifier';
  import Otp from '@/views/login/components/Otp';
  import Restart from '@/views/login/components/Restart';
  import FingerprintIdentification from "@/components/FingerprintIdentification";

  export default {

    components: { Identifier, Otp, Restart, FingerprintIdentification, Heading },

    data: () => ({
      problem: false,
      identifier: undefined,
      key: undefined,
      otp: undefined,
      invalidOtp: false,
      warehouseCreate: false,
      showWarehouse: false,
      warehouseName: undefined,
      fingerprintLogin: undefined,
    }),

    computed: {
      domain() {
        const hostname = window.location.hostname;
        let d = hostname.split('.')[0];
        if (hostname.includes('mazao')) { d = 'emazao'; }
        return d;
      },
      geolocation() {
        return (this.$store.getters.geolocation ? this.$store.getters.geolocation.coords : {});
      },
      appVersion() {
        return this.$store.getters.appVersion
      }
    },

    methods: {
      restart() {
        this.problem = false;
        this.identifier = undefined;
        this.key = undefined;
        this.otp = undefined;
        this.invalidOtp = false;
        this.warehouseCreate = (this.domain==='warehouse');
        this.showWarehouse = false;
      },
      login(payload) {
        if (payload) {
          this.$store.commit('setAuthKey', this.key );
          this.$store.commit('setUser', payload);

          if (!!payload['terms_and_conditions_at']) {
            this.$store.commit('acceptTermsAndConditions',{termsAndConditionTime: payload['terms_and_conditions_at']} );
          }

          if ((payload['customer_uuids'] || []).length==0) {
            this.$store.commit('customersHaveBeenInitialised');
          } else {
            this.$store.dispatch('addCustomersToCache', payload['customer_uuids']);
          }
        } else {
          this.invalidOtp = true;
          this.otp = undefined;
        }
      },
      initCreateWarehouse() {
        this.warehouseCreate = false;
        this.showWarehouse = true;
      },
    },

    watch: {
      identifier(to) {
        if (to) {
          this.problem = false;
          this.remoteLookup('sendOtp', { identifier: this.identifier, domain: this.domain, version: this.version }).then(e => this.key = e.key).catch(e => { this.problem = true; this.identifier = undefined; });
        }
      },
      otp(to) {
        if (to) {
          this.remoteLookup('validateOtp', {
            key: this.key,
            otp: to,
            domain: window.location.hostname,
            app_version: this.appVersion,
            latitude: this.geolocation.latitude,
            longitude: this.geolocation.longitude,
          }).then(e => this.login(e)).catch(e => { this.problem = true; this.identifier = undefined; });
        }
      },
      fingerprintLogin(to) {
        console.log("data from fingerprint login: ", to)
        if (to) {
          this.key = to.key
          try {
            this.login(to)
          } catch(e) {
            this.problem = true; this.identifier = undefined; 
          }
        }
        console.log("key: ", this.key)
      },
      domain: {
        immediate: true,
        handler(to) {
          if (to && to==='warehouse') {
            this.warehouseCreate = true;
          }
        }
      }
    },

    mixins: [lookups, domain],

  };
</script>


<i18n>
{
  "en": {
    "message": "Oops. Something went wrong. Please restart.",
    "invalidOtp": "Invalid telephone number or email address, or incorrect OTP. Please reenter.",
    "createNewWarehouse": "New? Create a new warehouse",
    "new": "Go",
    "warehousePlaceholder": "Warehouse Name"
  },
  "es": {
    "message": "Dios mío, algo salió mal. Por favor reinicie.",
    "invalidOtp": "Número de teléfono o dirección de correo electrónico no válidos o OTP incorrecta. Vuelva a ingresar.",
    "createNewWarehouse": "¿Nuevo? Crea un nuevo almacén",
    "new": "Ir",
    "warehousePlaceholder": "Nombre de la bodega"
  },
  "sw": {
    "message": "Ah mpenzi, kuna kitu kilienda vibaya. Tafadhali anzisha upya.",
    "invalidOtp": "Nambari ya simu au anwani ya barua pepe si sahihi, au OTP isiyo sahihi. Tafadhali ingiza tena.",
    "createNewWarehouse": "Mpya? Unda ghala mpya",
    "new": "Nenda",
    "warehousePlaceholder": "Jina la ghala"
  }
}
</i18n>
