<template>
  <v-container fluid>

    <v-row>

      <v-col cols="6" class="text-left">
        <TestCard ref="governmentInstallment" class="mx-auto" title="Government Installment" :results="governmentInstallment" @test="getGovernmentInstallment">
          <template v-slot:Inputs>
            <v-row>
              <v-col cols="6"><v-select :items="governmentAmounts" label="Loan Amount" prefix="TSh" v-model="governmentAffordabilityLoanAmount" /></v-col>
              <v-col cols="6"><v-select :items="governmentPeriods" label="Loan Duration (months)" v-model="governmentAffordabilityDurationMonths" /></v-col>
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              <Currency v-model="governmentInstallment.rounded" />
            </div>
            <div>
              Interest Rate: {{governmentInstallment.interest_rate*100}}%
            </div>
          </template>
        </TestCard>
      </v-col>

      <v-col cols="6" class="text-left">
        <TestCard ref="payDate" class="mx-auto" title="Next Pay Date" :results="nextPayDate" @test="getNextPayDate">
          <template v-slot:Inputs>
            <v-row>
              <v-col cols="6"><DateTimeInput v-model="paydateDate" /></v-col>
              <v-col cols="6"><v-select :items="days" label="Payroll Day" v-model="payrollDay" /></v-col>
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              <Time :time="nextPayDate" format="d MMM yyyy" />
            </div>
          </template>
        </TestCard>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="4" class="text-left">
        <TestCard ref="salaryAdvanceInstallment" class="mx-auto" title="Salary Advance Installment" :results="salaryAdvanceInstallment" @test="getSalaryAdvanceInstallment">
          <template v-slot:Inputs>
            <v-row>
              <v-col cols="12"><v-select :items="salaryAdvanceAmounts" label="Loan Amount" prefix="TSh" v-model="salaryAdvanceAffordabilityLoanAmount" /></v-col>
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              <Currency v-model="salaryAdvanceInstallment.installment" />
            </div>
            <div>
              Interest Rate: {{salaryAdvanceInstallment.interest_rate*100}}%
            </div>
          </template>
        </TestCard>
      </v-col>

      <v-col cols="4" class="text-left">
        <TestCard ref="salaryAdvanceAffordability" class="mx-auto" title="Salary Advance Affordability" :results="salaryAdvanceAffordability" @test="getSalaryAdvanceAffordability">
          <template v-slot:Inputs>
            <v-row>
              <v-col cols="4"><CurrencyInput v-model="netSalary" label="Nett Salary" /></v-col>
              <v-col cols="4"><v-text-field v-model.number="loanCycle" label="Loan Cycle" hide-details type="number" /></v-col>
              <v-col cols="4"><v-select :items="salaryAdvanceAmounts" label="Loan Amount" prefix="TSh" v-model="salaryAdvanceAffordabilityLoanAmount" /></v-col>
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              {{((salaryAdvanceAffordability || {}).decision ? 'Approve' : 'Reject')}}
            </div>
            <div>
              {{salaryAdvanceAffordability.reason}}
            </div>
          </template>
        </TestCard>
      </v-col>

      <v-col cols="4" class="text-left">
        <TestCard ref="salaryAdvanceLimit" class="mx-auto" title="Salary Advance Maximum" :results="salaryAdvanceLimit" @test="getSalaryAdvanceLimit">
          <template v-slot:Inputs>
            <v-row>
              <v-col cols="4"><CurrencyInput v-model="netSalary" label="Nett Salary" /></v-col>
              <v-col cols="4"><v-text-field v-model.number="loanCycle" label="Loan Cycle" hide-details type="number" /></v-col>
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              <Currency v-model="salaryAdvanceLimit.limit" />
            </div>
          </template>
        </TestCard>
      </v-col>
    </v-row>

    <v-row>

      <v-col cols="4" class="text-left">
        <TestCard ref="checkMeBackInstallment" class="mx-auto" title="Check Me Back Installment" :results="checkMeBackInstallment" @test="getCheckMeBackInstallment">
          <template v-slot:Inputs>
            <v-row>
              <v-col cols="6"><v-select :items="checkMeBackAmounts" label="Loan Amount" prefix="TSh" v-model="checkMeBackAffordabilityLoanAmount" /></v-col>
              <v-col cols="6"><v-select :items="[1,2,3,4,5,6,7]" label="Loan Duration (months)" v-model="checkMeBackAffordabilityDurationMonths" /></v-col>
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              <Currency v-model="(checkMeBackInstallment || {}).rounded" />
            </div>
            <div>
              Interest Rate: {{(checkMeBackInstallment || {}).interest_rate*100}}%
            </div>
          </template>
        </TestCard>
      </v-col>


      <v-col cols="4" class="text-left">
        <TestCard ref="checkMeBackAffordability" class="mx-auto" title="Check Me Back Affordability" :results="checkMeBackAffordability" @test="getCheckMeBackAffordability">
          <template v-slot:Inputs>
            <v-row>
              <v-col cols="4"><CurrencyInput v-model="grossSalary" label="Gross Salary" /></v-col>
              <v-col cols="4"><CurrencyInput v-model="netSalary" label="Nett Salary" /></v-col>
              <v-col cols="4"><v-text-field v-model.number="loanCycle" label="Loan Cycle" hide-details type="number" /></v-col>
              <v-col cols="4"><v-select :items="checkMeBackAmounts" label="Loan Amount" prefix="TSh" v-model="checkMeBackAffordabilityLoanAmount" /></v-col>
              <v-col cols="4"><CurrencyInput v-model="checkMeBackAffordabilityInstallment" label="Installment" /></v-col>
              <v-col cols="4"><v-select :items="[1,2,3,4,5,6,7]" label="Loan Duration (months)" v-model="checkMeBackAffordabilityDurationMonths" /></v-col>
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              {{((checkMeBackAffordability || {}).decision ? 'Approve' : 'Reject')}}
            </div>
            <div v-if="checkMeBackAffordability.reason">
              {{(checkMeBackAffordability || {}).reason}}
            </div>
          </template>
        </TestCard>
      </v-col>

      <v-col cols="4" class="text-left">
        <TestCard ref="checkMeBackLimit" class="mx-auto" title="Check Me Back Maximum" :results="checkMeBackLimit" @test="getCheckMeBackLimit">
          <template v-slot:Inputs>
            <v-row>
              <v-col cols="4"><CurrencyInput v-model="grossSalary" label="Gross Salary" /></v-col>
              <v-col cols="4"><CurrencyInput v-model="netSalary" label="Nett Salary" /></v-col>
              <v-col cols="4"><v-text-field v-model.number="loanCycle" label="Loan Cycle" hide-details type="number" /></v-col>
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              <Currency v-model="(checkMeBackLimit || {}).loan_amount" />; {{(checkMeBackLimit || {}).duration_months}} installments
            </div>
          </template>
        </TestCard>
      </v-col>

    </v-row>

  </v-container>
</template>



<script>

  import lookups from '@/mixins/lookups';

  import TestCard from '@/views/tests/TestCard';

  import DateTimeInput from '@/components/DateTimeInput';
  import Time from '@/components/Time';
  import CurrencyInput from '@/components/CurrencyInput';
  import Currency from '@/components/Currency';


  export default {

    components: { TestCard, DateTimeInput, Time, CurrencyInput, Currency },

    data: () => ({

      salaryAdvanceAmounts: [...Array(40).keys()].map( e => 50000+e*50000 ),
      checkMeBackAmounts: [...Array(99).keys()].map( e => 100000+e*50000 ),
      governmentAmounts: [...Array(199).keys()].map( e => 100000+e*50000 ),
      governmentPeriods: [...Array(36).keys()].map( e => e+1 ),

      paydateDate: new Date(),
      payrollDay: 25,
      days: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,'LD'],
      nextPayDate: undefined,

      grossSalary: 1375000,
      netSalary: 1205000,
      loanCycle: 2,

      salaryAdvanceAffordabilityLoanAmount: 450000,
      salaryAdvanceAffordability: undefined,
      salaryAdvanceLimit: undefined,

      salaryAdvanceInstallment: undefined,

      checkMeBackAffordabilityLoanAmount: 950000,
      checkMeBackAffordabilityInstallment: 3291500,
      checkMeBackAffordabilityDurationMonths: 7,

      checkMeBackAffordability: undefined,
      checkMeBackLimit: undefined,
      checkMeBackInstallment: undefined,

      governmentAffordabilityLoanAmount: 6800000,
      governmentAffordabilityDurationMonths: 12,

      governmentInstallment: undefined,

    }),

    watch: {
      paydateDate() { this.$refs.payDate.reset(); },
      payrollDay() { this.$refs.payDate.reset(); },

      salaryAdvanceAffordabilityLoanAmount() {
        this.$refs.salaryAdvanceInstallment.reset();
        this.$refs.salaryAdvanceAffordability.reset();
      },
      loanCycle() {
        this.$refs.salaryAdvanceAffordability.reset();
        this.$refs.checkMeBackAffordability.reset();
      },
      netSalary() {
        this.$refs.salaryAdvanceAffordability.reset();
        this.$refs.checkMeBackAffordability.reset();
      },

      checkMeBackAffordabilityLoanAmount() {
        this.$refs.checkMeBackInstallment.reset();
        this.$refs.checkMeBackAffordability.reset();
      },
      checkMeBackAffordabilityDurationMonths() {
        this.$refs.checkMeBackInstallment.reset();
        this.$refs.checkMeBackAffordability.reset();
      },
      grossSalary() { this.$refs.checkMeBackAffordability.reset(); },
      checkMeBackAffordabilityInstallment() { this.$refs.checkMeBackAffordability.reset(); },
      checkMeBackAffordabilityDurationMonths() { this.$refs.checkMeBackAffordability.reset(); },

      checkMeBackInstallment(to) {
        if (to) {
          this.checkMeBackAffordabilityInstallment = to.rounded;
        }
      },
    },

    methods: {
      getNextPayDate() {
        this.remoteLookup('testUltimateTzPayDate', { paydateDate: this.paydateDate, payrollDay: this.payrollDay }).then( nextPayDate => this.nextPayDate = nextPayDate ).catch(e => this.nextPayDate = null);
      },
      getSalaryAdvanceAffordability() {
        this.remoteLookup('testUltimateTzSalaryAdvanceAffordability', {
          loanAmount: this.salaryAdvanceAffordabilityLoanAmount,
          cycle: this.loanCycle,
          netSalary: this.netSalary,
        }).then( affordability => this.salaryAdvanceAffordability = affordability ).catch(e => this.salaryAdvanceAffordability = null);
      },
      getSalaryAdvanceLimit() {
        this.remoteLookup('testUltimateTzSalaryAdvanceLimit', {
          netSalary: this.netSalary,
          cycle: this.loanCycle,
        }).then( maximum => this.salaryAdvanceLimit = maximum ).catch(e => this.salaryAdvanceLimit = null);
      },
      getSalaryAdvanceInstallment() {
        this.remoteLookup('testUltimateTzSalaryAdvanceInstallment', {
          loanAmount: this.salaryAdvanceAffordabilityLoanAmount,
        }).then( installment => this.salaryAdvanceInstallment = installment.result ).catch(e => this.salaryAdvanceInstallment = null);
      },
      getCheckMeBackAffordability() {
        this.remoteLookup('testUltimateTzCheckMeBackAffordability', {
          loanAmount: this.checkMeBackAffordabilityLoanAmount,
          cycle: this.loanCycle,
          netSalary: this.netSalary,
          grossSalary: this.grossSalary,
          installment: this.checkMeBackAffordabilityInstallment,
          durationMonths: this.checkMeBackAffordabilityDurationMonths,
        }).then( affordability => this.checkMeBackAffordability = affordability ).catch(e => this.checkMeBackAffordability = null);
      },
      getCheckMeBackLimit() {
        this.remoteLookup('testUltimateTzCheckMeBackLimit', {
          netSalary: this.netSalary,
          grossSalary: this.grossSalary,
          cycle: this.loanCycle,
        }).then( maximum => this.checkMeBackLimit = maximum ).catch(e => this.checkMeBackLimit = null);
      },
      getCheckMeBackInstallment() {
        this.remoteLookup('testUltimateTzCheckMeBackInstallment', {
          loanAmount: this.checkMeBackAffordabilityLoanAmount,
          durationMonths: this.checkMeBackAffordabilityDurationMonths,
        }).then( installment => this.checkMeBackInstallment = installment ).catch(e => this.checkMeBackInstallment = null);
      },
      getGovernmentInstallment() {
        this.remoteLookup('testUltimateTzGovernmentInstallment', {
          loanAmount: this.governmentAffordabilityLoanAmount,
          durationMonths: this.governmentAffordabilityDurationMonths,
        }).then( installment => this.governmentInstallment = installment ).catch(e => this.governmentInstallment = null);
      },
    },

    mixins: [lookups],

  }
</script>
