var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ServerSideResult',{attrs:{"test":_vm.history}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.history,"items-per-page":25},scopedSlots:_vm._u([{key:"item.disbursed_at",fn:function(ref){
var item = ref.item;
return [(item.disbursed_at)?_c('Time',{attrs:{"time":item.disbursed_at}}):_c('code',[_vm._v("No")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.instrumentTypeToName(item.type))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('Currency',{attrs:{"value":parseInt(item.amount)}}):_vm._e()]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":parseInt(item.balance),"cents":""}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status=='closed')?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-check-bold")]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","to":{name: 'UltimateTzStatement', query: { id: item.id, uuid: _vm.uuid } }}},[_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v("mdi-history")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }