<template>
    <v-container>
        <h3 v-if="relations">Next Of Kin Details</h3>
        <h3 v-if="!relations">No Next of kin Given</h3>
        <v-simple-table v-if="relations">    
        <tbody>
            <Row title="Relationship"           :text="nextOfKin.relationship" v-if="relations" />
            <Row title="Name"                   :text="kinName" bold="true" v-if="kinName" class="capitalize" />
            <Row title="Telephone">
            <Telephone :msisdn="nextOfKin.msisdn" />
            <span v-if="nextOfKin.alternative_msisdn">&nbsp;/&nbsp;</span>
            <Telephone :msisdn="nextOfKin.alternative_msisdn" v-if="customer.alternative_msisdn" />
            </Row>
        </tbody>
        </v-simple-table>
    </v-container>
</template>

<script>
import Row from "@/components/Row";
import Telephone from "@/components/Telephone";

export default {

  components: { Row, Telephone },

  props: {
    customer: {
      type: Object,
      required: true,
    }
  },


  computed: {
    uuid() { return this.customer.uuid; },
    nextOfKin() {return ((this.customer.demographic || {}).relations || [])[0]},
    relations() {
        return this.nextOfKin
    },
    kinName() {
        if(this.relations) {
            console.log("next of kin", this.nextOfKin)
            let firstName = this.nextOfKin.first_name || this.nextOfKin.firstName
            let middleName = this.nextOfKin.middle_name || this.nextOfKin.middleName
            let surname = this.nextOfKin.surname
            return ( (this.nextOfKin.name || `${(firstName || '')} ${middleName || ''} ${surname || ''}`))
        }
    }
  },

}
</script>

<style scoped>
.capitalize {
    text-transform: capitalize;
}
</style>