<template>

  <ServerSideResult :test="doNotLend">
    <SalaryAdvance :customer="customer" v-if="loanType=='InstrumentUltimateTzSalaryAdvance'" />
    <CheckMeBack   :customer="customer" v-else-if="loanType=='InstrumentUltimateTzCheckMeBack'" />
    <InEligible    :customer="customer" v-else />
  </ServerSideResult>

</template>

<script>
import lookups from '@/mixins/lookups';

import SalaryAdvance from "@/views/partners/ultimate_tz/SalaryAdvance";
import CheckMeBack from "@/views/partners/ultimate_tz/CheckMeBack";
import InEligible from "@/views/customers/partners/ultimate_tz/components/InEligible";
import ServerSideResult from '@/components/ServerSideResult';

export default {

  components: { SalaryAdvance, CheckMeBack, InEligible, ServerSideResult },

  props: {
    customer: {
      type: Object,
      required: true,
    },
    openLoans: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      doNotLend: undefined,
    }
  },

  computed: {
    availableLoanTypes() {
      let r = [];
      if ( (this.customer.employer || {}).category==='bank' ) {
        r.push('InstrumentUltimateTzCheckMeBack');
      } else {
        r.push('InstrumentUltimateTzSalaryAdvance');
      }
      return r;
    },
    current() { return (this.openLoans || []).map( e => e.type ); },
    // choices() { return this.availableLoanTypes.filter(n => !this.current.includes(n)); },
    eligible() { return ( this.current.filter(n => (n==='InstrumentUltimateTzCheckMeBack' || n==='InstrumentUltimateTzCheckMeBack') ) ).length==0; },
    choices() { return (this.eligible ? this.availableLoanTypes : [] ); },
    loanType() { return (this.doNotLend ? undefined : this.choices[0]) },
  },

  mounted() {
    this.remoteLookup('do_not_lend', { uuid: this.customer.uuid }).then( dnl => this.doNotLend = dnl.do_not_lend ).catch(e => this.doNotLend = null);
  },

  mixins: [lookups],

}
</script>
